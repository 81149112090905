import * as sdk from 'matrix-js-sdk';

export const loginByPassword = async (baseUrl, user, password) => {
    const client = sdk.createClient({
        baseUrl
    });

    return await client.login('m.login.password', {
        identifier: {
            type: 'm.id.user',
            user,
        },
        password,
        type: 'm.login.password',
    });
}

export const loginByToken = async (baseUrl, token) => {
    const client = sdk.createClient({
        baseUrl
    });

    return await client.login('m.login.token', {
        token,
        type: 'm.login.token'
    });
}

export const getMatrixMode = async baseUrl => {
    try {
        const response = await fetch(baseUrl + '/q/health', {
            method: 'GET'
        });
        if (response.status === 200) {
            const json = await response.json();
            let mode = null;
            json.checks.forEach(check => {
                if (check.data && check.data.matrix_mode) {
                    mode = check.data.matrix_mode;
                }
            });
            return mode;
        } else {
            throw new Error(await response.text());
        }
    } catch (error) {
        return 'matrix'
    }
}
