import BElement from '../../BElement.js'
import {getMatrixClient} from '../control/MatrixClient.js';
import {setMatrixState, setMatrixUpdateState} from '../control/MatrixControl.js';
import {addNotificationState} from '../../notification/control/NotificationsControl.js';
import {addInformationMessage} from '../../messages/control/MessagesControl.js';
import {nothing} from 'lit-html';

class Matrix extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix,
        }
    }

    view() {
        if (getMatrixClient() && !this.state.matrix.client_started) {
            setMatrixState({name: 'client_started', value: true});
            this.onStartClient().catch(r => setMatrixState({name: 'client_started', value: false}));
        }
        return nothing;
    }

    onStartClient = async () => {
        console.log('************Starting Client');
        try {
            await getMatrixClient().startClient();
        } catch (err) {
            console.error('***********' + err);
        }
        getMatrixClient().once('sync', (state, prevState, res) => {
        });
        getMatrixClient().on('event', async event => {
            setMatrixUpdateState();
        });
        getMatrixClient().on('RoomMember.typing', (event, member) => {
            setMatrixUpdateState();
        });
        getMatrixClient().on('crypto.verification.request', verificationRequest => {
            this.clearVerificationRequests();
            this.addVerificationRequest(verificationRequest);
            addNotificationState({
                title: 'crypto.verification.request',
                link: '/messenger/devices/verification/receiver/'
            });
            addInformationMessage('crypto.verification.request received');
        });
        getMatrixClient().on('toDeviceEvent', async event => {
                if (event.event.type.startsWith('m.key.verification')) {
                    console.log('m.key.verification event received:', event);
                }

                if (event.event.type === 'm.key.verification.request') {
                } else if (event.event.type === 'm.key.verification.cancel') {
                } else if (event.event.type === 'm.key.verification.start') {
                }
            }
        );
        getMatrixClient().on('Room.myMembership', (room, memberShip) => {
            const name = room.currentState.getStateEvents('m.room.name').pop().event.content.name;
            const creator = room.currentState.getStateEvents('m.room.create').pop().event.sender;
            const invitations = this.state.matrix.invitations.filter(e => e.room_id !== room.roomId);
            if (memberShip === 'invite') {
                invitations.push({room_name: name, room_id: room.roomId, room_creator: creator});
            }
            setMatrixState({name: 'invitations', value: invitations});
            setMatrixUpdateState();
        });
    }

    addVerificationRequest = event => {
        if (!globalThis.verificationRequests) {
            globalThis.verificationRequests = [];
        }
        globalThis.verificationRequests.push(event);
    }

    clearVerificationRequests = () => {
        globalThis.verificationRequests = null;
    }
}

customElements.define('kosyma-matrix', Matrix)
